import React, { useEffect, useState, useRef } from 'react';
import useMeasure from 'react-use-measure';
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import { useSpring, to as interpolate, animated, config } from '@react-spring/web';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { media } from 'utils/mediaQuery';

const useGesture = createUseGesture([dragAction, pinchAction])

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgb(var(--brand-black));
`

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: grab;
  touch-action: none;
  user-select: none;

  ${media.small`
    cursor: auto;
  `}
`

const Grid = styled(animated.div)`
  display: grid;
`

const ImageWrapper = styled.div`
  position: relative;
  transition: opacity .2s ease;
  pointer-events:none;
`

const Image = styled(GatsbyImage)`
  position: relative;
`

const FeaturedZoomGrid = ({nodes, x = 0, y = 0, indexHovered, show}) => {
  const defaultScale = useRef();
  const scaleCache = useRef();
  const showCache = useRef(false);
  const interactRef = useRef();
  const initScale = useRef(true)
  const GridRef = useRef();
  const [ Scale, setScale ] = useState(null);
  const [ GridX, setGridX ] = useState(0);
  const [ GridY, setGridY ] = useState(0);
  const [ IndexHovered, setIndexHovered ] = useState(null);
  const [ref, {width,height}] = useMeasure();
  const [ props, api ] = useSpring(() => ({ to: { x: x, y: y, opacity: 0, colwidth: 40, gap: 10, scaleValue: 5}, immediate: true }))

  useEffect(() => {

    const onWheel = (event) => {
      const wheelThresh = 1000;
      const scrollPercentage = (event.deltaY * -1) / wheelThresh;
      const scrollAmount = defaultScale.current * 0.5;

      const newScale = scaleCache.current + (scrollPercentage * scrollAmount);

      if(newScale <= defaultScale.current + scrollAmount && newScale >= defaultScale.current - scrollAmount) {
        scaleCache.current = newScale;
        setScale(scaleCache.current);
      }
      
    } 

    if(show) {
      window.addEventListener('wheel', onWheel)

      api.start({
        opacity: 1,
        config: {
          duration: 200
        }
      })
    }
    
    return () => {
      window.removeEventListener('wheel', onWheel)
      api.start({
        opacity: 0
      })
    }
  }, [show])


  /**
   * Ensure default gesture listeners are disabled
   */

  useEffect(() => {
    const handler = e => e.preventDefault()
    document.addEventListener('gesturestart', handler)
    document.addEventListener('gesturechange', handler)
    document.addEventListener('gestureend', handler)
    return () => {
      document.removeEventListener('gesturestart', handler)
      document.removeEventListener('gesturechange', handler)
      document.removeEventListener('gestureend', handler)
    }
  }, [])

  useEffect(() => {
    if(width > 0 && height > 0) {
      const imageWidth = 0.6 * width;
      const scale = imageWidth / 40;

      setScale(Math.floor(scale));
      defaultScale.current = Math.floor(scale);
      scaleCache.current = defaultScale.current;

      api.start({
        scaleValue: defaultScale.current,
        immediate: true
      })

      getThumbFocusCoords(defaultScale.current,2,2);
    } 

  }, [width, height])

  useEffect(() => {

    if(Scale && x !== undefined && y !== undefined) {
      let newX = (x * Scale * -1) + width / 2;
      let newY = (y * Scale * -1)  + height / 2;
      let colWidth =  Scale * 40;
      let gap = Scale * 10;

      if(initScale.current) {
        api.start({
          colwidth: colWidth,
          gap: gap,
        })
      } else {
        api.start({
          x: newX,
          y: newY,
          colwidth: colWidth,
          gap: gap,
        })
      }
      
      

    }
   
  }, [Scale])

  useEffect(() => {
    if(Scale && x !== undefined && y !== undefined && x !== 0 && y !== 0) {
      let newX, newY;
      if(show && !showCache.current) {
        newX = (x * Scale * -1) + width / 2;
        newY = (y * Scale * -1)  + height / 2;
        api.start({
          x: newX,
          y: newY,
          immediate: true
        })
        showCache.current = true;
      } else if( show && showCache.current){
        newX = (x * Scale * -1) + width / 2;
        newY = (y * Scale * -1)  + height / 2;
        api.start({
          x: newX,
          y: newY,
        })
      } else if(!show && showCache.current) {
        showCache.current = false;
      }
    }
  }, [x,y,show])

  useEffect(() => {
    //Resets teh scale to default
    /*if(!indexHovered) {
      setScale(defaultScale.current);
    }*/

    setIndexHovered(indexHovered)
  }, [indexHovered])

  useGesture(
    {
      // onHover: ({ active, event }) => console.log('hover', event, active),
      // onMove: ({ event }) => console.log('move', event),
      onDrag: ({ pinching, cancel, offset: [ox,oy], ...rest }) => {
        if (pinching) return cancel()

          api.start({
            x: ox,
            y: oy,
            config: {
              mass:1, 
              tension:1000, 
              friction:100
            }
          })
      },
      onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo }) => {
        if(first) {
          const dx = props.x.get() - width / 2;
          const dy = props.y.get() - height / 2;
          memo = [props.x.get(), props.y.get(), s, dx, dy]
        }

        const scale = s;
        let x = memo[0] + ((ms-1)*memo[3]);
        let y = memo[1] + ((ms-1)*memo[4]);
        let colWidth =  scale * 40;
        let gap = scale * 10;
        
        api.start({
            x,
            y,
            scaleValue: scale,
            colwidth: colWidth,
            gap: gap,
            config: config.default
          })
  
        return memo;
      }
    },
    {
      target: interactRef,
      drag: { from: () => [props.x.get(), props.y.get()] },
      pinch: { from: () => [props.scaleValue.get(), 0], scaleBounds: {min: 1, max: 10 } },
    }
  )

  const getThumbFocusCoords = (scale, col, row) => {
    const colWidth = 40;
    const rowHeight = 50;
    const gap = 10;
    const actualX = (col * colWidth) - (colWidth / 2) + ((col - 1) * gap);
    const actualY = (row * rowHeight) - (rowHeight / 2) + ((row - 1) * gap);

    let newX = (actualX * scale * -1) + width / 2;
    let newY = (actualY * scale * -1)  + height / 2;

    api.start({
      x: newX,
      y: newY,
      immediate: true,
      onRest: () => {
        // set init scale at false so the Scale useEffect can do its own x y coords
        initScale.current = false;
      }
    })
  }

  return (
    <Wrapper ref={ref}>
      <Inner ref={interactRef}>
        <Grid ref={GridRef} style={{
                transform: interpolate(
                  [props.x,props.y],
                  (x,y) => `translate3d(${x}px,${y}px,0)`
                ),
                opacity: interpolate(
                  [props.opacity],
                  (opacity) => `${opacity}`
                ),
                gridTemplateColumns: interpolate(
                  [props.colwidth],
                  (colwidth) => `repeat(6,${colwidth}px)`
                ),
                gap: interpolate(
                  [props.gap],
                  (gap) => `${gap}px`
                )
              }}>
        { nodes && nodes.map((node, i) => {
          const imageObject = node.asset ? getImage(node.asset) : null;
          return ( 
          <ImageWrapper
            key={i}
            style={{opacity: IndexHovered !== null && IndexHovered !== i ? 0.5 : 1}} >
            <Image  
              image={imageObject}
              alt={node.alt}
              objectFit="cover"
              objectPosition="center"
              sizes='(max-width: 800px) 400px, (max-width: 1200px) 500px, (max-width: 1920px) 600px, (max-width: 2520px) 700px, (min-width: 2521px) 900px'
              layout="fullWidth"/>
            </ImageWrapper>
          )
        })}
        </Grid>
      </Inner>
    </Wrapper>
  )
}

export default FeaturedZoomGrid;
