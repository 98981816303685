import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import Featured from "sections/Featured/Featured";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    thumbnails: sanityFeatured {
      images {
        alt
        asset {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            layout: FIXED
            outputPixelDensities: [1,2]
            width: 40
          )
        }
      }
    }
    featured: sanityFeatured {
      images {
        alt
        asset {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors, location } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const featuredNodes = (data || {}).featured.images;
  const thumbnailNodes = (data || {}).thumbnails.images;
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout omitFooter location={location}>
      <SEO title={"Abdela Igmirien"} description={site.description} keywords={site.keywords} />
      <Featured nodes={featuredNodes} thumbnails={thumbnailNodes}/>
    </Layout>
  );
};

export default IndexPage;
