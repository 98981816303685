import React, { useState, useRef } from 'react';
import useMeasure from 'react-use-measure';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding: 70px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 40px);
  column-gap: 10px;
  row-gap: 10px;
  grid-auto-columns: min-content;
`

const ImageWrapper = styled.div`
  position: relative;
  transition: opacity .2s ease;
`

const Image = styled(GatsbyImage)`
  position: relative;
  pointer-events: none;
`

const FeaturedThumbGrid = ({nodes, updatePos, updateIndex, setShowZoom, ...rest}) => {
  const [ref, bounds] = useMeasure();
  const timer = useRef(null);
  const [ IndexHovered, setIndexedHover ] = useState(null);

  const enterThumbsGrid = (event) => {
    const x = event.clientX - bounds.x;
    const y = event.clientY - bounds.y;
    if(updatePos) {
      updatePos(x,y);
    }
    
    setShowZoom(true);
  }

  const leaveThumbsGrid = () => {
    setShowZoom(false);
  }

  const onMouseMove = (event) => {
    const x = event.clientX - bounds.x;
    const y = event.clientY - bounds.y;

    if(updatePos) {
      updatePos(x,y);
    }
  }

  const killTimer = () => {
    if(timer.current) {
      clearTimeout(timer.current);
    }
  }

  const onMouseEnterThumb = (index) => {
    killTimer();
    setIndexedHover(index)
    updateIndex(index)
  }

  const onMouseLeaveThumb = () => {
    killTimer();
    timer.current = setTimeout(() => {
      setIndexedHover(null);
      updateIndex(null)
    }, 200)
    
  }

  return (
    <Wrapper 
      onMouseMove={onMouseMove}  
      onMouseEnter={enterThumbsGrid} 
      onMouseLeave={leaveThumbsGrid} {...rest}>
      <Grid ref={ref}>
      { nodes && nodes.map((node, i) => {
        const imageObject = node.asset ? getImage(node.asset) : null;
        return (
          <ImageWrapper
            key={i}
            onMouseOver={()=>onMouseEnterThumb(i)}
            onMouseOut={onMouseLeaveThumb}
            style={{opacity: IndexHovered !== null && IndexHovered !== i ? 0.5 : 1}}
          >
            <Image 
              image={imageObject}
              backgroundColor={imageObject.backgroundColor}
              alt={node.alt}
              sizes='(max-width: 800px) 400px, (max-width: 1200px) 500px, (max-width: 1920px) 600px, (max-width: 2520px) 700px, (min-width: 2521px) 900px'
              objectFit="cover"
              objectPosition="center"
              layout="fullWidth" />
          </ImageWrapper>
        )
      })}
      </Grid>
    </Wrapper>
  )
}

export default FeaturedThumbGrid;
