import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import FeaturedThumbGrid from 'modules/FeaturedThumbGrid/FeaturedThumbGrid';
import FeaturedZoomGrid from 'modules/FeaturedZoomGrid/FeaturedZoomGrid';
import useMatchMedia from 'utils/matchMedia';
import { media } from 'utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: calc((var(--vh,1vh) * 100) - 212px);

  ${media.small`
    height: 100vh;
  `}
`

const Half = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.small`
    width: 50%;
  `}
`

const ThumbGridHalf = styled(Half)`
  display: none;
  ${media.small`
    display: flex;
  `}
`

const Featured = ({nodes,thumbnails}) => {
  const isDesktop = useMatchMedia('(min-width:768px)', true)
  const [ X, setX ] = useState(0);
  const [ Y, setY ] = useState(0); 
  const [ IndexHovered, setIndexHovered ] = useState(null); 
  const [ ShowZoom, setShowZoom ] = useState(false);
  const updater = (x,y) => {
    setX(x);
    setY(y);
    
  }

  const updateIndex = (index) => {
    setIndexHovered(index);
  } 

  useEffect(() => {
    if(!isDesktop) {
      setShowZoom(true)
    } else {
      setShowZoom(false)
    }
    
  },[isDesktop])
  
  return (
    <Wrapper>
      { isDesktop && <ThumbGridHalf>
        <Link to="/projects">
          <FeaturedThumbGrid 
            nodes={nodes} 
            updatePos={updater} 
            updateIndex={updateIndex} 
            setShowZoom={setShowZoom}/>
        </Link>
      </ThumbGridHalf>}
      <Half>
        <FeaturedZoomGrid 
          nodes={nodes} 
          x={X} 
          y={Y} 
          indexHovered={IndexHovered} 
          show={ShowZoom}/>
      </Half>
    </Wrapper>
  )
}

export default Featured;
